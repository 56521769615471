<template>
  <v-dialog v-model="dialog" width="100%" max-width="1300px" max-height="94%" height="100%" scrollable content-class="mt-16 payment-modal">
    <template v-slot:activator="{ on, attrs }">
      <v-btn id="par" v-if="type === 'button'" color="secondary" v-bind="attrs" v-on="on">
        <span class="whitespace-normal"><slot></slot></span>
      </v-btn>
      <a v-else href="#" class="" v-bind="attrs" v-on="on">
        <slot></slot>
      </a>
    </template>

    <v-card class="pb-4">
      <v-toolbar flat dense>
        <div class="flex-grow-1"></div>
        <v-btn icon small @click="dialog = false" color="text-gray-400">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        {{ $t("paymentAssistanceRequest.title") }}
      </v-card-title>
      <v-card-text>
        <p v-if="!success" style="font-size: 16px;">
          {{ $t("paymentAssistanceRequest.body") }}
        </p>

        <p v-if="!success">* Required Fields</p>
        <p v-if="success"><strong>Thank you</strong></p>

        <v-form v-if="!success" v-model="valid">
          <v-text-field
            class="mt-8 field-name"
            v-model.trim="form.name"
            :rules="rules.required"
            :label="'* ' + $t('interface.name')"
            required
            outlined
            @change="clearErrors('field-name')"
          ></v-text-field>

          <div class="flex justify-between -mx-2">
            <v-text-field
              class="px-2"
              :label="'* ' + $t('interface.streetAddress')"
              :value="$store.state.entry.addresses.mailing.street"
              required
              disabled
              outlined
            ></v-text-field>
          </div>
          <div class="flex justify-between -mx-2 mobile-flex-undo">
            <v-text-field
              class="px-2"
              :label="'* ' + $t('interface.city')"
              :value="$store.state.entry.addresses.mailing.city"
              required
              disabled
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              :label="'* ' + $t('interface.state')"
              :value="$store.state.entry.addresses.mailing.state"
              required
              disabled
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              :label="'* ' + $t('interface.postal')"
              :value="$store.state.entry.addresses.mailing.postal"
              required
              disabled
              outlined
            ></v-text-field>
          </div>

          <v-text-field
            class="field-phone"
            v-model="form.phone"
            v-mask="'###-###-####'"
            :rules="rules.phone"
            :label="'* ' + $t('interface.phone')"
            required
            outlined
            @change="clearErrors('field-phone')"
          ></v-text-field>

          <v-text-field
            v-model.trim="form.email"
            :rules="rules.email"
            :label="$t('interface.email')"
            outlined
          ></v-text-field>

          <!--<v-text-field v-model="form.age" :label="$t('interface.age')" :rules="rules.number" outlined></v-text-field>-->
          <v-text-field
            v-model="form.dateOfBirth"
            :rules="[dateOfBirth]"
            :label="'* ' + $t('interface.dateOfBirth')"
            placeholder="mm/dd/yyyy"
            v-mask="'##/##/####'"
            hint="Examples: 01/01/1999, 12/31/1999"
            required
            outlined
            class="field-dateOfBirth"
            @change="clearErrors('field-dateOfBirth')"
          ></v-text-field>

          <p class="mt-0 mb-4">
            Demographic data is used to help us evaluate the effectiveness of our services and will not impact your
            ability to receive services
          </p>

          <v-select
            v-model="form.race"
            :rules="rules.required"
            :items="[
              'American Indian or Alaska Native',
              'Asian',
              'Black or African American',
              'Hispanic or Latino',
              'Native Hawaiian or Other Pacific Islander',
              'White',
              'Prefer not to answer',
              'Other',
            ]"
            item-text="text"
            item-value="value"
            required
            multiple
            :label="'* ' + $t('interface.race')"
            outlined
            persistent-hint
            class="field-race"
            @change="clearErrors('field-race')"
          ></v-select>

          <v-text-field
            v-if="form.race.includes('Other')"
            class="px-2"
            :label="'Other Race'"
            v-model.trim="form.race_other"
            outlined
          ></v-text-field>

          <div class="mobile-field-label">'* ' + Do you identify as Hispanic or Latino?</div>
          <v-select
            v-model="form.ethnicity"
            :items="['Hispanic or Latino', 'Non-Hispanic', 'Prefer not to answer']"
            item-text="text"
            item-value="value"
            required
            :label="'* ' + 'Do you identify as Hispanic or Latino?'"
            outlined
            persistent-hint
            class="field-ethnicity"
            @change="clearErrors('field-ethnicity')"
          ></v-select>

          <v-select
            v-model="form.gender"
            :items="['Female', 'Male', 'Other', 'Prefer not to answer']"
            :rules="rules.required"
            :label="'* ' + $t('interface.gender')"
            outlined
            class="field-gender"
            @change="clearErrors('field-gender')"
          ></v-select>

          <div class="mobile-field-label">* Are you a U.S. Military Veteran?</div>
          <v-select
            v-model="form.military_veteran"
            :items="['Yes', 'No']"
            :label="'* ' + 'Are you a U.S. Military Veteran?'"
            :rules="rules.required"
            class="field-military_veteran"
            @change="clearErrors('field-military_veteran')"
            outlined
          ></v-select>

          <div class="mobile-field-label">Do you have any medical conditions or disabilities?</div>
          <v-select
            v-model="form.medical_conditions"
            :items="[
              'Diabetes',
              'Hypertension',
              'Dementia',
              'Vision loss',
              'Hearing loss',
              'Arthritis',
              'Cancer',
              'Mental health issues',
              'Uses Cane/Walker/Wheelchair/Scooter',
              'None',
              'Other',
            ]"
            multiple
            :label="'Do you have any medical conditions or disabilities?'"
            outlined
          ></v-select>

          <v-text-field
            v-if="form.medical_conditions.includes('Other')"
            class="px-2"
            :label="'Other Medical Conditions'"
            v-model.trim="form.medical_conditions_other"
            outlined
          ></v-text-field>

          <div class="mobile-field-label">* Can you shop for food and other things you need without help?</div>
          <v-select
            v-model="form.can_shop"
            :items="['Yes', 'No']"
            :label="'* ' + 'Can you shop for food and other things you need without help?'"
            :rules="rules.required"
            class="field-can_shop"
            @change="clearErrors('field-can_shop')"
            outlined
          ></v-select>

          <div class="mobile-field-label">* Can you prepare meals for yourself without help?</div>
          <v-select
            v-model="form.prepare_meals"
            :items="['Yes', 'No']"
            :label="'* ' + 'Can you prepare meals for yourself without help?'"
            :rules="rules.required"
            outlined
            class="field-prepare_meals"
            @change="clearErrors('field-prepare_meals')"
          ></v-select>

          <div class="mobile-field-label">
            * Can you handle your own finances, like keeping track of bills without help?
          </div>
          <v-select
            v-model="form.handle_finances"
            :items="['Yes', 'No']"
            :label="'* ' + 'Can you handle your own finances, like keeping track of bills without help?'"
            :rules="rules.required"
            outlined
            class="field-handle_finances"
            @change="clearErrors('field-handle_finances')"
          ></v-select>

          <div class="mobile-field-label">
            * Can you use public transportation or drive beyond walking distances without help?
          </div>
          <v-select
            v-model="form.public_transportation"
            :items="['Yes', 'No']"
            :label="'* ' + 'Can you use public transportation or drive beyond walking distances without help?'"
            :rules="rules.required"
            class="field-public_transportation"
            @change="clearErrors('field-public_transportation')"
            outlined
          ></v-select>

          <v-radio-group v-model="form.householdStatus" :mandatory="true">
            <v-radio :label="$t(`household.forSelf.livesAloneOption`)" value="single"></v-radio>
            <v-radio :label="$t(`household.forSelf.livesWithOthersOption`)" value="multiple"></v-radio>
          </v-radio-group>
          <div class="mobile-field-label">{{ $t("household.form.householdMonthlyIncome") }}</div>
          <v-select
            v-if="form.householdStatus === 'single'"
            v-model="form.incomeRange"
            :items="[
              'Up to $1,255',
              '$1,256-$1,882',
              '$1,883-$2,510',
              '$2,511-$3,137',
              '$3,138-$3,765',
              '$3,766 and over',
              'I don\'t know/prefer not to answer',
            ]"
            :rules="rules.required"
            :label="'* ' + $t('household.form.householdMonthlyIncome')"
            outlined
            class="field-incomeRange"
            @change="clearErrors('field-incomeRange')"
          ></v-select>
          <v-select
            v-if="form.householdStatus === 'multiple'"
            v-model="form.incomeRange"
            :items="[
              'Up to $1,703',
              '$1,704-$2,555',
              '$2,556-$3,406',
              '$3,407-$4,258',
              '$4,259-$5,110',
              '$5,110 and over',
              'I don\'t know/prefer not to answer',
            ]"
            :rules="rules.required"
            :label="'* ' + $t('household.form.householdMonthlyIncome')"
            outlined
            class="field-incomeRange"
            @change="clearErrors('field-incomeRange')"
          ></v-select>

          <div class="mobile-field-label">
            * Are you able to contribute toward the cost of each meal? If so, select the dollar amount you are able to
            contribute:
          </div>
          <v-select
            v-model="form.cost_toward_meal"
            :items="['$6', '$5', '$4', '$3', '$2', '$1', 'I am not able to contribute']"
            :label="
              '* ' +
              'Are you able to contribute toward the cost of each meal? If so, select the dollar amount you are able to contribute:'
            "
            :rules="rules.required"
            outlined
            class="field-cost_toward_meal"
            @change="clearErrors('field-cost_toward_meal')"
          ></v-select>

          <!-- <v-checkbox
            class="-mt-1 mb-2"
            v-model="form.affectedByCovid"
            :label="$t('paymentAssistanceRequest.affectedByCovid')"
          ></v-checkbox> -->

          <v-select
            v-model="form.primaryLanguage"
            :items="['English', 'Spanish', 'Somali', 'Other']"
            :rules="rules.required"
            :label="'* ' + $t('interface.primaryLanguageSpoken')"
            outlined
            class="field-primaryLanguage"
            @change="clearErrors('field-primaryLanguage')"
          ></v-select>

          <v-text-field
            v-if="form.primaryLanguage === 'Other'"
            v-model.trim="form.otherLanguage"
            :label="$t('interface.otherLanguage')"
            outlined
          ></v-text-field>

          <div class="flex justify-between -mx-2 mobile-flex-undo">
            <v-text-field
              class="px-2"
              :label="'Emergency Contact Name'"
              v-model.trim="form.emergency_contact_name"
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              :label="'Emergency Phone'"
              v-model="form.emergency_phone"
              v-mask="'###-###-####'"
              outlined
            ></v-text-field>
            <div class="mobile-field-label" style="padding-left: 10px">Emergency Contact Relationship</div>
            <v-text-field
              class="px-2"
              :label="'Emergency Contact Relationship'"
              v-model.trim="form.emergency_contact_relationship"
              outlined
            ></v-text-field>
          </div>

          <v-textarea
            v-model.trim="form.commentsAndQuestions"
            :label="$t('paymentAssistanceRequest.commentsAndQuestions')"
            outlined
          ></v-textarea>

          <h4 class="mt-6 mb-4">
            Do you receive other forms of assistance or would you like help applying to receive them? Please select all
            that apply:
          </h4>

          <div class="mobile-field-label" style="padding-left: 10px">
            WIC (Food assistance for Women, Infants and Children)
          </div>
          <div class="flex justify-between -mx-2">
            <v-select
              v-model="form.other_assistance_wic"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'WIC (Food assistance for Women, Infants and Children)'"
              class="px-2"
              outlined
            ></v-select>
          </div>

          <div class="flex justify-between -mx-2 mobile-flex-undo">
            <v-select
              v-model="form.other_assistance_snap"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'SNAP benefits (food stamps)'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_medicaid"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Medicaid'"
              class="px-2"
              outlined
            ></v-select>
          </div>

          <div class="flex justify-between -mx-2 mobile-flex-undo">
            <v-select
              v-model="form.other_assistance_elderly"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Elderly Waiver'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_disability"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Disability Waiver'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_foodshelf"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Food Shelf/Shelves'"
              class="px-2"
              outlined
            ></v-select>
          </div>

          <div class="flex justify-between -mx-2 mobile-flex-undo">
            <v-select
              v-model="form.other_assistance_freemeal"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Free Congregate Dining Meal'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_veterans"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Veterans Benefits'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_rent"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Rent Assistance'"
              class="px-2"
              outlined
            ></v-select>
            <v-select
              v-model="form.other_assistance_heating"
              :items="['Receive', 'Have Applied', 'Need Assistance to Apply']"
              :label="'Heating Assistance'"
              class="px-2"
              outlined
            ></v-select>
          </div>

          <v-checkbox
            class="-mt-1 mb-2 field-use_of_information"
            @change="clearErrors('field-use_of_information')"
            v-model="form.use_of_information"
            :rules="rules.required"
            :label="'I understand that meal assistance is subject to available funding and may be discontinued or reduced at any time. I understand that the information I am providing on this form is for registration purposes. In addition, information provided here, may be used by other service providers to help identify other services from which I may benefit. This information will not be released to anyone in a way that will identify me as an individual unless I sign a separate consent for that purpose.'"
          ></v-checkbox>

          <!-- <v-btn :disabled="!valid" v-on:click="submit" color="primary">{{ $t("interface.submit") }}</v-btn> -->
          <v-btn v-on:click="submit" color="primary">{{ $t("interface.submit") }}</v-btn>
        </v-form>
        <div v-else>
          <!-- {{ $t("paymentAssistanceRequest.success") }} -->
          <p class="custom-messages-font-size">
            Thank you for your interest in receiving Meals on Wheels! We look forward to the opportunity to serve you. A
            representative from your neighborhood Meals on Wheels program will contact you within 3 business days to
            arrange service and answer any questions you may have. If you have questions about your request or Meals on
            Wheels in the meantime, please call 612-623-3363 or email
            <a href="mailto:info@meals-on-wheels.com">info@meals-on-wheels.com</a>. <br /><br />
            If you are facing an immediate food-related emergency or are looking for additional hunger relief resources,
            please contact the Minnesota Food Helpline at 1-888-711-1151. <br />
            <br /><br />
            <button
              type="button"
              class="mt-4 v-btn v-btn--contained theme--light v-size--default primary"
              @click="goHome()"
            >
              <span class="v-btn__content">Done</span>
            </button>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PaymentAssistanceRequest",
  components: {},
  props: {
    type: {
      type: String,
      default: "button",
    },
  },
  data: () => ({
    dialog: false,
    valid: false,
    success: false,
    vueAppSiteUrl:
      process.env.NODE_ENV === "production"
        ? "https://meals-on-wheels.com"
        : "https://staging.mammoth-yard.flywheelsites.com",
    form: {
      name: "",
      phone: "",
      email: "",
      dateOfBirth: "",
      ethnicity: "",
      race: "",
      race_other: "",
      householdStatus: "",
      incomeRange: "",
      affectedByCovid: "",
      primaryLanguage: "",
      otherLanguage: "",
      commentsAndQuestions: "",
      gender: "",
      military_veteran: "",
      medical_conditions: "",
      medical_conditions_other: "",
      can_shop: "",
      prepare_meals: "",
      handle_finances: "",
      public_transportation: "",
      cost_toward_meal: "",
      emergency_phone: "",
      emergency_contact_name: "",
      emergency_contact_relationship: "",
      use_of_information: "",
      other_assistance_snap: "",
      other_assistance_wic: "",
      other_assistance_medicaid: "",
      other_assistance_elderly: "",
      other_assistance_disability: "",
      other_assistance_foodshelf: "",
      other_assistance_freemeal: "",
      other_assistance_veterans: "",
      other_assistance_rent: "",
      other_assistance_heating: "",
    },
    rules: {
      required: [(v) => !!v || "This field is required"],
      number: [(v) => v.search(/^\d+$/) > -1 || "Please enter a number"],
      email: [
        (v) => {
          return (
            !v ||
            v.search(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) > -1 ||
            "Please enter a valid email"
          );
        },
      ],
      ethnicity: [(v) => v.length > 0 || "An ethnicity is required"],
      phone: [
        (v) => {
          return (
            v.search(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/) > -1 || "Please enter a valid phone number"
          );
        },
      ],
    },
  }),
  methods: {
    submit() {
      if (this.formValid()) {
        fetch(`${process.env.VUE_APP_API_URL}/payment-assistance-requests`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: this.$store.state.id,
            entry: {
              ...{
                address: this.$store.state.entry.addresses.mailing,
                district: this.$store.state.entry.district,
                name:
                  this.$store.state.entry.personalInformation.firstName +
                  " " +
                  this.$store.state.entry.personalInformation.lastName,
              },
              ...this.form,
            },
          }),
        })
          .then(() => {
            this.success = true;
            // Go back to the step 2?
            this.$store.commit("setStep", 2);
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.error(`Error: `, error);
          });
      }
    },
    goHome() {
      window.top.location.href = this.vueAppSiteUrl;
    },
    clearErrors: (classString) => {
      const fieldElements = document.getElementsByClassName(classString);
      for (const fieldElement of Object.entries(fieldElements)) {
        const inputSlots = fieldElement[1].getElementsByClassName("v-input__slot");
        for (const inputSlot of Object.entries(inputSlots)) {
          inputSlot[1].classList.remove("field-missing");
        }
      }
    },
    formValid() {
      let requiredFields = [
        { field: this.form.name, message: "Name", class: "field-name" },
        { field: this.form.phone, message: "Phone", class: "field-phone" },
        { field: this.form.dateOfBirth, message: "Date of birth", class: "field-dateOfBirth" },
        { field: this.form.race, message: "Race", class: "field-race" },
        { field: this.form.ethnicity, message: "Ethnicity", class: "field-ethnicity" },
        { field: this.form.can_shop, message: "Can you shop for food and other things you need without help?", class: "field-can_shop" },
        { field: this.form.prepare_meals, message: "Can you prepare meals for yourself without help?", class: "field-prepare_meals" },
        {
          field: this.form.handle_finances,
          message: "Can you handle your own finances, like keeping track of bills without help?",
          class: "field-handle_finances"
        },
        {
          field: this.form.public_transportation,
          message: "Can you use public transportation or drive beyond walking distances without help?",
          class: "field-public_transportation"
        },
        { field: this.form.incomeRange, message: "Monthly income range", class: "field-incomeRange" },
        { field: this.form.cost_toward_meal, message: "Meal contribution", class: "field-cost_toward_meal" },
        { field: this.form.primaryLanguage, message: "Primary language", class: "field-primaryLanguage" },
        { field: this.form.use_of_information, message: "Use of information", class: "field-use_of_information" },
      ];

      let errorMessageString = "";
      let valid = true;

      let classRemovals = document.getElementsByClassName("v-input__slot");
      for (const classRemoval of Object.entries(classRemovals)) {
        classRemoval[1].classList.remove("field-missing");
      }

      for (const requiredField of Object.entries(requiredFields)) {
        if (typeof requiredField[1].field === "undefined" || !requiredField[1].field) {
          errorMessageString = errorMessageString + requiredField[1].message + "\r\n";
          let fieldElements = document.getElementsByClassName(requiredField[1].class);
          for (const fieldElement of Object.entries(fieldElements)) {
            let inputSlots = fieldElement[1].getElementsByClassName("v-input__slot");
            for (const inputSlot of Object.entries(inputSlots)) {
              inputSlot[1].classList.add("field-missing");
            }
          }
        }
      }

      if (errorMessageString) {
        valid = false;
        alert("Please complete these required fields: \r\n\r\n" + errorMessageString);
      }
      return valid;
    },
    dateOfBirth: (value) => {
      const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2][0-9]|3[01])\/\d{4}$/;
      if (value.search(regex) < 0) {
        return "A date of birth is required (mm/dd/yyyy)"
      } else {
        const dateArr = value.split("/");
        const dob = Math.floor(new Date(dateArr[2], dateArr[0] - 1, dateArr[1]).getTime() / 1000);
        const minDate = Math.floor(new Date(1900, 0, 1, 0,0,0,0) / 1000);
        const maxDate = new Date().setHours(0,0,0,0) / 1000;
        return (minDate <= dob && dob <= maxDate) || "Valid birthday must be between 01/01/1900 and today"
      }
    }
  },
};
</script>
